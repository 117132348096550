import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import ComingSoon from "./userPages/ComingSoon";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          {/* User Routes */}
          <Route exact path="/" element={<ComingSoon />} />
          <Route exact path="/coming-soon" element={<ComingSoon />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
