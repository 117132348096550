import React, { useState } from "react";
import MetaTags from "react-meta-tags";
// assets
import Logo from "../assets/logo.png";
// import LogoTitle from "../assets/logo-title.png"
// import FacebookLogo from "../assets/Symbol.png"
// import TwitterLogo from "../assets/Symbol (1).png"
// import InstagramLogo from "../assets/Symbol (2).png"
// import LinkedinLogo from "../assets/Symbol (3).png"
import YellowFish from "../assets/2.png";
import BlueFish from "../assets/3.png";
import Turtle from "../assets/1.png";
import LemonFish from "../assets/4.png";
import star from "../assets/6.png";
import darkFish from "../assets/7.png";
import bg from "../assets/bg.png";
// react icons
import {
  FaXTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa6";

const ComingSoon = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  const handleBackgroundLoad = () => {
    setBackgroundLoaded(true);
  };
  return (
    <div className="catchfish-comingsoon" style={{ background: `url(${bg})` }}>
      <img
        src={bg}
        style={{ display: "none" }}
        alt="Preload Background"
        onLoad={handleBackgroundLoad}
      />
      <MetaTags>
        <title>Catch Fish - Amazon Management Agency</title>
        <meta
          property="og:title"
          content="Catch Fish - Amazon Management Agency"
        />
        <meta property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      {backgroundLoaded && (
        <div className="logo-wrapper fish-behind">
          <div style={{ position: "absolute", opacity: "0" }}>
            Catch Fish - Amazon Management Agency, specialists in Account
            management, Content optimisation, Advertisement. We are your
            dedicated partner helping your brands growth and success on the
            world’s largest online marketplace Amazon!
          </div>
          <img src={Logo} width="146px" height="146px" alt="Logo" />
          <div className="logo-title-wrapper">
            <p className="fish-behind">CATCH</p>
            <div style={{ zIndex: 9999999999 }}>
              <p className="black fish-text">FISH</p>
            </div>
          </div>
          <div>
            <p className="slogan fish-behind">
              Specialists in Amazon Management Services
            </p>
            <h2 className="coming-soon-title fish-behind">Coming Soon</h2>
            {/* <p className="second-slogan fish-behind">
            Your digital <span className="black">partner</span>, reeling in your{" "}
            <span className="black">success</span>
          </p> */}
          </div>
          <div className="social-media-wrapper fish-behind">
            <p className="social-media">@catchfishagency</p>
            <div className="social-container">
              <div className="social-media-container">
                {/* <img src={FacebookLogo} width="30px" height="30px" alt="Facebook logo" /> */}
                <FaFacebook style={{ fontSize: "30px", color: "white" }} />
                <FaXTwitter style={{ fontSize: "30px", color: "white" }} />
                <FaInstagram style={{ fontSize: "30px", color: "white" }} />
                <FaLinkedin style={{ fontSize: "30px", color: "white" }} />

                {/* <img src={InstagramLogo} width="30px" height="30px" alt="Instagram logo" /> */}
                {/* <img src={LinkedinLogo} width="30px" height="30px" alt="Linkedin logo" /> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Animated Fish */}
      {backgroundLoaded && (
        <div className="animation-container">
          <img src={YellowFish} alt="Yellow fish" className="yellow-fish" />
          {/* <img src={BlueFish} alt="Yellow fish" className="blue-fish" /> */}
          <img src={Turtle} alt="Turtle fish" className="turtle" />{" "}
          <img src={darkFish} alt="Lemon fish" className="turtle1" />
          {/* <img src={LemonFish} alt="Lemon fish" className="lemon-fish" /> */}
          {/* <img
          src={star}
          alt="Turtle fish"
          className="turtle"
          style={{ width: "150px" }}
        /> */}
        </div>
      )}
    </div>
  );
};

export default ComingSoon;
